/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/image';
@import '../../style/abstract/parts';

:root {
    --cart-page-discount-heading-margin-bottom: 1.8rem;
    --cart-page-element-gap: 2.8rem;
    --cart-page-totals-background: #F9F9F9;
    --cart-page-totals-border: 1px solid var(--input-border-color);
    --navigation-tabs-height: 0;

    --cartpage-discount-padding: 3rem;
    --cartpage-discount-box-shadow: 0 0 5px rgba(0,0,0,0.1);
    --cartpage-discount-heading-font-size: 1.6rem;
    
    @include mobile {
        --cartpage-discount-padding: 2.5rem 2rem;
        --cartpage-discount-heading-font-size: 1.4rem;
    }
}

.CartPage {
    --footer-totals-height: 28px;

    &-Wrapper {
        padding-right: 0 !important;
        padding-left: 0 !important;

        display: block;
        margin-top: 3rem;
    }

    &-Static {
        @include mobile {
            overflow: unset;
        }
    }

    &-Top {
        margin-bottom: var(--cart-page-element-gap);

        @include desktop {
            margin-bottom: 0;
            display: grid;
            grid-template-columns: 2.9fr 1.1fr;
            grid-column-gap: var(--cart-page-element-gap);
        }
    }

    &-Bottom {
        display: grid;
        grid-row-gap: var(--cart-page-element-gap);
        grid-template-columns: 1fr;
        margin-top: 3rem;

        @include desktop {
            grid-template-columns: 1.8fr 1.1fr 1.1fr;
            grid-column-gap: var(--cart-page-element-gap);
        }

        .ProductLinks {
            @include mobile {
                order: 3;
            }

            grid-column: auto;

            &-Wrapper {
                padding: 0;
            }

            &-Title {
                margin-top: 0;
                margin-bottom: var(--cart-page-element-gap);
                padding: 0;
            }

            &-List {
                display: block;

                @include desktop {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: var(--cart-page-element-gap);
                }

                .ProductCard {
                    @include mobile {
                        margin-bottom: var(--cart-page-element-gap);
                    }
                }
            }
        }
    }

    &-TableHead {
        background-color: transparent;
        padding: 0;
        border-bottom: 0;
        font-size: 1.3rem;
        text-transform: capitalize;

        span {
            &::after {
                content: ':'
            }

            &:last-of-type {
                padding-right: 1rem;
            }
        }
    }

    &-Items {
        .CartItem-Actions {
            &_isEditing {
                @include desktop {
                    right: 18%;
                }
            }
        }
    }

    &-Summary {
        &.FixedElement-Bottom {
            background-color: var(--cart-page-totals-background);
            
            @include mobile {
                z-index: 11;
            }
        }

        @include mobile {
            position: fixed;
            bottom: var(--navigation-tabs-height);
            left: 0;
            width: 100%;
            border-top: 1px solid var(--primary-divider-color);
        }

        @include desktop {
            border: var(--cart-page-totals-border);
            padding: 2rem;
            padding-top: 0;
        }
    }

    &-Total {
        @include desktop {
            border-bottom: 1px solid var(--cart-page-divider-background);
            font-weight: 400;
    
            dt,
            dd {
                font-size: 1.4rem;
            }
    
            dd {
                font-weight: 700;
            }
        }
    }

    &-CheckoutButtons {
        @include desktop {
            text-align: right;
        }
    }

    &-CheckoutButton {
        width: 100%;
        display: flex;
        align-items: center;

        @include desktop {
            font-size: 1.4rem;
        }
    }

    &-DeleteAllButton {
        @include mobile {
            display: none;
        }

        text-transform: uppercase;
        text-align: inherit;
        color: $black;
        display: block;
        font-size: 1.2rem;
        margin: 2.5rem 0 2.5rem auto;
    
        &:hover {
            text-decoration: underline;
        }
    
        &:focus {
            text-decoration: underline;
        }
    }

    &-Discount {
        margin-top: 0;
        padding: var(--cartpage-discount-padding);
        box-shadow: var(--cartpage-discount-box-shadow);

        @include mobile {
            order: 1;
            padding-top: 1.5rem;
        }

        // @include additional-card;

        .CartCoupon {
            display: flex;
            flex-direction: column;

            &-Button {
                @include mobile {
                    width: auto;
                }

                margin-left: auto;
            }
        }

        .Field {
            margin-top: 0;
            margin-bottom: 1.4rem;
            display: block;
            
            > * {
                width: 100%;
            }
        }
    }

    &-DiscountHeading {
        margin-bottom: var(--cart-page-discount-heading-margin-bottom);
        font-size: var(--cartpage-discount-heading-font-size);
    }

    .CartItem {
        &-QuantityValue {
            display: none;
        }
        
        &-Content {
            > .CartItem-QuantityWrapper {
                display: none;
            }
        }
    }

    &-Empty {
        border-bottom: 0;
    }
}
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

$white: #fff;
$black: #272323;
$default-primary-base-color: #222222;
$default-primary-dark-color: darken($default-primary-base-color, 15%);
$default-primary-light-color: lighten($default-primary-base-color, 15%);
$default-secondary-base-color: #eee;
$default-secondary-dark-color: darken($default-secondary-base-color, 15%);
$default-secondary-light-color: lighten($default-secondary-base-color, 15%);
$default-primary-error-color: #d20b0b;
$default-secondary-error-color: #fff;
$font-basic: 'Work Sans', sans-serif;
$font-muli: 'Muli', sans-serif;
$font-standard-size: 62.5%;

$default-header-search-background-color-mobile: $default-primary-base-color;
$default-header-background-color: $white;
$default-header-color: $black;
$default-header-icons-color: $black;

$default-usps-background-color: $white;
$default-usps-icon-color: $black;
$default-usps-color: $black;

$default-store-notice-background-color: $white;
$default-store-notice-color: $black;

$default-menu-background-color: $white;
$default-menu-color: $black;

$default-dropdown-menu-background-color: $white;
$default-dropdown-menu-color: $black;

$default-footer-column-content-color: var(--body-content-color);
$default-footer-column-title-color: $default-footer-column-content-color;
$default-footer-content-background-color: var(--color-gray);
$default-footer-content-border-color: transparent;
$default-footer-copyright-background-color: var(--color-white);
$default-footer-column-title-font-size: 2rem;
$default-footer-column-title-font-weight: 700;
$default-footer-column-title-text-transform: uppercase;

$default-footer-content-background-color-mobile: $default-footer-content-background-color;
$default-footer-column-content-background-color-mobile: var(--color-light-gray);
$default-footer-column-title-font-size-mobile: 1.6rem;
$default-footer-column-title-text-transform-mobile: uppercase;
$default-footer-column-newsletter-color-mobile: $default-footer-column-content-color;
$default-footer-column-newsletter-background-color-mobile: $default-primary-base-color;
